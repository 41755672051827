<template>
  <layout-login>
    <template v-slot:title>
      {{ __('interface.sign-in') }}
    </template>
    <ion-item>
      <ion-label position="floating">{{ __('interface.email') }}</ion-label>
      <ion-input type="email" v-model="form.email" autocomplete="username" inputmode="password" spellcheck="true"
                 required/>
    </ion-item>
    <ion-item>
      <ion-label position="floating">{{ __('interface.password') }}</ion-label>
      <ion-input :type="inputType" v-model="form.password" autocomplete="current-password" inputmode="password"
                 spellcheck="true" required @change="changePasswordInput"/>
      <ion-button fill="clear" slot="end" class="ion-align-self-end" icon-only style="margin-left:0;" @click="toggleShow()">
        <ion-icon :icon="showPassword ? eyeOff : eye"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-button expand="full" class="ion-margin-vertical" @click="actionLogin">{{
        __('interface.sign-in')
      }}
    </ion-button>
    <div class="ion-text-center ion-padding">
      <!-- <router-link @click="$router.push('/forgot-password')">{{ __('interface.forgot-password') }}</router-link> -->
      <router-link to="/forgot-password">{{ __('interface.forgot-password') }}</router-link>
    </div>
    <div class="ion-text-center ion-padding">
      <!-- <router-link @click="$router.push('/register')">{{ __('interface.register') }}</router-link> -->
      <router-link to="/register">{{ __('interface.register') }}</router-link>
    </div>
    <div class="ion-text-center ion-padding">
      <!-- <router-link @click="$router.push('/register')">{{ __('interface.register') }}</router-link> -->
      <a :href="trainerLink">{{ __('interface.trainer-redirect') }}</a>
    </div>
  </layout-login>
</template>
<script>
import {alertController, loadingController} from '@ionic/vue';
import {Auth} from '@aws-amplify/auth'
import LayoutLogin from "../../layouts/LayoutLogin";
import AuthService from "@/views/AuthService";
import {IonIcon} from '@ionic/vue';
import {eye, eyeOff} from 'ionicons/icons';

export default {
  name: 'Login',
  mixins: [AuthService],
  components: {
    LayoutLogin,
    IonIcon
  },        
  setup() {
    return {
      eye,
      eyeOff
    }
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      showPassword: false,
      inputType: 'password',
      trainerLink: process.env.VUE_APP_TrainerUrl
    }
  },
  async ionViewWillEnter() {
    await this.checkNoAuth();
  },
  methods: {
    async actionLogin() {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await loading.present();

      Auth.signIn(this.form.email, this.form.password)
        .then(() => {
          this.$router.push('/tabs')
          this.$tracking.event({ eventName: 'login', category: 'auth' });
        })
        .catch(error => {
          this.showAlert(error.message)
        })
        .finally(() => {
          loading.dismiss();
        });
    },
    async showAlert(error) {
      const alert = await alertController.create({
        header: 'Error',
        message: error,
        buttons: ['OK'],
      });
      return alert.present();
    },
    changePasswordInput(e) {
      this.form.password = e.target.value
    },
    toggleShow () {
      this.showPassword = !this.showPassword
      this.inputType = this.inputType === 'text' ? 'password' : 'text'
    }
  }
}
</script>
