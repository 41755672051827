<template>
  <ion-page>
    <ion-header translucent>
      <ion-toolbar>
        <ion-title>
          <slot name="title"></slot>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
        <div class="login-logo">
          <img :src="logoSrc" alt="The Spott" height="120">
        </div>
        <slot></slot>
      </ion-content>
  </ion-page>
</template>

<script>
  import { IonPage } from '@ionic/vue';
  import { layout } from '@/utils/layout'

  export default {
  name: 'LayoutLogin',
  mixins: [layout],
  components: {
    IonPage,
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.login-logo {
  display: flex;
  justify-content: center;
}

</style>
